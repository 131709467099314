<template>
    <div>
        <input type="file" ref="image_file_input" accept="image/jpg,image/png,image/gif,image/svg+xml,image/jpeg,image/vnd.microsoft.icon" v-show="false" @change="onFileSelected($event, 'favicon_url')"/>
        <v-img v-if="value" @click="triggerInput" :max-height="$vuetify.breakpoint.mdAndUp ? previewMaxHeight : ''" :max-width="previewMaxWidth" :src="value" class="mx-auto pointer image-preview" contain></v-img>
        <v-img v-else-if="!loading && imageResult" @click="triggerInput" :max-height="$vuetify.breakpoint.mdAndUp ? previewMaxHeight : ''" :max-width="previewMaxWidth" :src="imageResult" class="mx-auto pointer image-preview" contain></v-img>
        <div v-else @click="triggerInput" class="d-flex mx-auto justify-center align-center pointer image-box" :style="{ height: previewMaxHeight , width: previewMaxWidth }">
            <div class="d-flex flex-column">
                <v-icon class="mx-auto primary--text">$cp_upload_cloud</v-icon>
                <span class="mt-1 mx-auto secondary_font--text">{{ $t('components.inputs.ImageInput.add') }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import HasErrorHandlerMixin from '@/mixins/HasErrorHandlerMixin'
import cleanAxios from 'axios'

export default {
    name: 'ImageInput',
    props: {
        value: String,
        type : String,

        previewMaxHeight: String,
        previewMaxWidth : String,
        postUpload      : Function,
        params          : {
            type: Object,
            default: () => ({}),
        },

        maxSizeMB: {
            default: () => 10,
        },
        manual: Boolean,
    },
    data: vm => ({
        loading    : false,
        isPreview  : false,
        hasError   : false,
        imageResult: null,
        imageFile  : null,
        prevValue  : null,
    }),
    mixins: [ HasErrorHandlerMixin ],
    methods: {
        triggerInput() {
            if (!this.hasError)
                this.$refs.image_file_input.click()
        },
        async onFileSelected(event, attributeName) {
            if (this.loading)
                return

            const files = event.target.files

            if (files[0] !== undefined) {
                let imageName = files[0].name
                if (imageName.lastIndexOf('.') <= 0) {
                    return
                }

                this.loading = true

                // Valida o tamanho do arquivo
                if (files[0].size / 1048576 > this.maxSizeMB) {
                    this.isPreview = false
                    this.loading = false
                    await new Promise((resolve) => {
                        this.$bus.$emit('alert', this.$t(`components.inputs.ImageInput.size_error`, { max: this.maxSizeMB }), 'error', resolve)
                    })
                    return
                }

                if (!this.prevValue)
                    this.prevValue = this.value

                const fr = new FileReader
                fr.readAsDataURL(files[0])
                fr.addEventListener('load', async () => {
                    this.imageResult = fr.result
                    this.imageFile   = files[0]   // this is an image file that can be sent to server...

                    this.$emit('input', fr.result)
                    this.loading = false
                    // this.requestFileUpload(fr.result, files[0])

                    if (!this.manual)
                        this.isPreview = true
                })
            }
        },

        async requestFileUpload() {
            this.loading = true
            let result   = this.imageResult
            let file     = this.imageFile

            if (!result || !file) {
                this.loading = false
                return
            }

            let res = await this.$axios.post('/file/request', { action: this.type, file_type: file.type, params: this.params })
                .catch(this.preErrorHandler)
            this.loading = false

            if (!res)
                return

            this.loading = true
            let postUploadConfig = res.data

            // Cria todos os campos indicados pelo createPresignedPost() no backend
            const formData = new FormData()
            formData.append('acl', 'public-read')
            formData.append('Content-Type', file.type)
            Object.entries(postUploadConfig.fields).forEach(([k, v]) => {
                formData.append(k, v)
            })
            formData.append('file', file) // must be the last one

            res = await cleanAxios({
                url   : postUploadConfig.url,
                method: 'POST',
                data  : formData,
            })
            .catch(this.preErrorHandler)

            if (!res)
                return

            // O timestamp garante que irá ser feito o refresh
            let newUrl = `${postUploadConfig.url.replace(/\/$/, '')}/${postUploadConfig.fields.key}?${Date.now()}`

            // Se precisa realizar algo depois do upload
            if (typeof this.postUpload === 'function') {
                await this.postUpload(newUrl)
                    .catch(this.preErrorHandler)
            }

            // Atualiza o caminho para a imagem no modelo
            this.$emit('input',  newUrl)
            this.$emit('upload', file, newUrl)

            // Mensagem de sucesso e limpar props
            this.$bus.$emit('message', this.$t('components.inputs.ImageInput.success'), 'success')
            this.isPreview = false
            this.loading = false
        },

        cancel() {
            this.isPreview = false
            if (this.prevValue)
                this.$emit('input', this.prevValue)
            this.prevValue = null
        },

        preErrorHandler(e) {
            this.loading = false
            let status   = this.$lodash.get(e, 'response.status')
            let data     = this.$lodash.get(e, 'response.data')

            // Verifica se é algum erro previsto do S3
            if (status == 400) {
                let xmlDoc
                if (window.DOMParser) {
                    let parser = new DOMParser()
                    if (data)
                        xmlDoc = parser.parseFromString(data, 'text/xml')
                }
    
                let code = xmlDoc ? xmlDoc.getElementsByTagName('Code')[0].childNodes[0].nodeValue : null
                if (code == 'EntityTooLarge') {
                    let MaxSizeAllowed = xmlDoc.getElementsByTagName('MaxSizeAllowed')[0].childNodes[0].nodeValue
                    let maxInMB        = this.$lodash.round(MaxSizeAllowed / 1000000, 2)
                    this.$bus.$emit('message', this.$t('components.inputs.ImageInput.size_error', { max: maxInMB }), 'warning')
                    return
                }
            }

            // Se não é um erro previsto, sobe para o errorHandler geral
            this.hasError = true
            this.errorHandler(e)
        },
    },
}
</script>

<style scoped lang="scss">
.image-box {
    background: var(--v-table_divider_color-base);
    border: dashed 1px var(--v-secondary_font-base);
    border-radius: 8px;
}

.image-preview {
    border-radius: 8px !important;
}
</style>