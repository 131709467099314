var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("input", {
        directives: [
          { name: "show", rawName: "v-show", value: false, expression: "false" }
        ],
        ref: "image_file_input",
        attrs: {
          type: "file",
          accept:
            "image/jpg,image/png,image/gif,image/svg+xml,image/jpeg,image/vnd.microsoft.icon"
        },
        on: {
          change: function($event) {
            return _vm.onFileSelected($event, "favicon_url")
          }
        }
      }),
      _vm.value
        ? _c("v-img", {
            staticClass: "mx-auto pointer image-preview",
            attrs: {
              "max-height": _vm.$vuetify.breakpoint.mdAndUp
                ? _vm.previewMaxHeight
                : "",
              "max-width": _vm.previewMaxWidth,
              src: _vm.value,
              contain: ""
            },
            on: { click: _vm.triggerInput }
          })
        : !_vm.loading && _vm.imageResult
        ? _c("v-img", {
            staticClass: "mx-auto pointer image-preview",
            attrs: {
              "max-height": _vm.$vuetify.breakpoint.mdAndUp
                ? _vm.previewMaxHeight
                : "",
              "max-width": _vm.previewMaxWidth,
              src: _vm.imageResult,
              contain: ""
            },
            on: { click: _vm.triggerInput }
          })
        : _c(
            "div",
            {
              staticClass:
                "d-flex mx-auto justify-center align-center pointer image-box",
              style: {
                height: _vm.previewMaxHeight,
                width: _vm.previewMaxWidth
              },
              on: { click: _vm.triggerInput }
            },
            [
              _c(
                "div",
                { staticClass: "d-flex flex-column" },
                [
                  _c("v-icon", { staticClass: "mx-auto primary--text" }, [
                    _vm._v("$cp_upload_cloud")
                  ]),
                  _c(
                    "span",
                    { staticClass: "mt-1 mx-auto secondary_font--text" },
                    [_vm._v(_vm._s(_vm.$t("components.inputs.ImageInput.add")))]
                  )
                ],
                1
              )
            ]
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }